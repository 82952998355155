<ng-container *ngIf="{
    settings: settings$ | async,
    currentData: currentData$ | async,
    constants: constants$ | async
  } as state">
  <section class="flex flex-col min-h-screen bg-gray-50 p-4 gap-4" *ngIf="state.currentData">
    <!-- Header Section -->
    <div class="bg-white rounded-xl shadow-md overflow-hidden">
      <!-- User Info Card -->
      <div class="p-6">
        <div class="text-center mb-4">
          <div *ngIf="state.currentData?.user?.profilePhoto" class="mb-4 flex justify-center">
            <img [src]="state.currentData?.user?.profilePhoto"
              class="w-24 h-24 rounded-full object-cover border-4 border-gray-100 shadow-sm" alt="Foto de perfil" />
          </div>
          <h2 class="text-2xl font-bold text-gray-800">
            {{ state.currentData?.user?.fname }}
            {{ state.currentData?.user?.lname }}
          </h2>
          <p class="text-gray-500 mt-1">
            {{ state.currentData?.user?.cid | number : "1.0-0" }}
          </p>
          <p class="text-gray-500">Miembro {{ state.currentData.user?.isShareOwner ? "Propietario" : "Familiar" }}</p>
        </div>

        <!-- User Details Grid -->
        <div class="grid grid-cols-2 gap-4 text-center">
          <div class="bg-gray-50 p-3 rounded-lg">
            <p class="text-sm text-gray-500">Acción</p>
            <span class="font-semibold text-sm">{{ state.currentData?.share?.shareNumber }}</span>
          </div>
          <div class="bg-gray-50 p-3 rounded-lg">
            <p class="text-sm text-gray-500">Estado</p>
            <span class="font-semibold text-sm text-green-600">Activo</span>
          </div>

        </div>
      </div>
    </div>

    <!-- QR Code Section -->
    <div class="flex flex-col justify-center items-center w-full">
      <div class="bg-white w-full p-6 rounded-xl shadow-lg mx-auto flex flex-col justify-center items-center flex-1">
        <qrcode *ngIf="state.currentData"
          [qrdata]="formatCid(state.currentData?.user?.cid, state.currentData?.user.nacionality)"
          [imageSrc]="'/assets/img/cdi-logo.png'" [allowEmptyString]="true" [imageHeight]="80" [imageWidth]="80"
          [width]="300" [cssClass]="'qrcode'" [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [margin]="2"
          [errorCorrectionLevel]="'M'"></qrcode>

        <p class="text-center text-gray-600 mt-4">
          Presente este código QR en recepción para acceder
        </p>
      </div>
    </div>
  </section>
</ng-container>