import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable, from, shareReplay } from 'rxjs';

interface WPPost {
  title: { rendered: string };
  excerpt: { rendered: string };
  link: string;
  date: string;
  new?: boolean;
  uagb_featured_image_src: { full: string[] };
}

interface CacheData {
  timestamp: number;
  posts: WPPost[];
}

@Injectable({
  providedIn: 'root',
})
export class WpService {
  private readonly API_URL =
    'https://casaditalia.com.ve/wp-json/wp/v2/posts?tags=7,8&per_page=3';
  private readonly CACHE_KEY = 'wp_posts_cache';
  private readonly CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

  private postsCache$ = new BehaviorSubject<WPPost[]>([]);

  constructor(private http: HttpClient, private platform: Platform) {
    this.initializeCache();
  }

  // Helper method to check if we're on a mobile platform
  private get isMobile(): boolean {
    return this.platform.IOS || this.platform.ANDROID;
  }

  // Store data using the appropriate storage method
  private async setStorageItem(key: string, value: string): Promise<void> {
    if (this.isMobile) {
      await Preferences.set({ key, value });
    } else {
      localStorage.setItem(key, value);
    }
  }

  // Get data using the appropriate storage method
  private async getStorageItem(key: string): Promise<string | null> {
    if (this.isMobile) {
      const { value } = await Preferences.get({ key });
      return value;
    }
    return localStorage.getItem(key);
  }

  // Remove data using the appropriate storage method
  private async removeStorageItem(key: string): Promise<void> {
    if (this.isMobile) {
      await Preferences.remove({ key });
    } else {
      localStorage.removeItem(key);
    }
  }

  getPosts(): Observable<WPPost[]> {
    return from(this.getPostsAsync()).pipe(shareReplay(1));
  }

  private async getPostsAsync(): Promise<WPPost[]> {
    const cachedData = await this.getCachedData();

    if (cachedData && this.isCacheValid(cachedData.timestamp)) {
      return this.getLatestPosts(cachedData.posts);
    }

    try {
      const posts = await this.http
        .get<WPPost[]>(this.API_URL, {
          headers: { 'Content-Type': 'application/json' },
        })
        .toPromise();

      const latestPosts = this.getLatestPosts(posts || []);
      await this.updateCache(latestPosts);
      return latestPosts;
    } catch (error) {
      console.error('Error fetching posts:', error);
      const expiredCache = await this.getCachedData();
      return expiredCache?.posts || [];
    }
  }

  async clearCache(): Promise<void> {
    await this.removeStorageItem(this.CACHE_KEY);
    this.postsCache$.next([]);
  }

  private async initializeCache(): Promise<void> {
    const cachedData = await this.getCachedData();
    if (cachedData) {
      this.postsCache$.next(cachedData.posts);
    }
  }

  private async updateCache(posts: WPPost[]): Promise<void> {
    const cacheData: CacheData = {
      timestamp: Date.now(),
      posts,
    };

    await this.setStorageItem(this.CACHE_KEY, JSON.stringify(cacheData));
    this.postsCache$.next(posts);
  }

  private async getCachedData(): Promise<CacheData | null> {
    try {
      const cached = await this.getStorageItem(this.CACHE_KEY);
      return cached ? JSON.parse(cached) : null;
    } catch (error) {
      console.error('Error reading cache:', error);
      return null;
    }
  }

  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.CACHE_DURATION;
  }

  private getLatestPosts(posts: WPPost[]): WPPost[] {
    const [first, second, third] = posts.sort((a, b) => {
      const dateA = new Date(a?.date).getTime();
      const dateB = new Date(b?.date).getTime();
      return dateB - dateA;
    });

    const structuredOrder = [second, { ...first, new: true }, third];
    return structuredOrder;
  }
}
