import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  ArrowLeftOutline,
  CalendarOutline,
  DashboardOutline,
  DeleteFill,
  DeleteOutline,
  EditOutline,
  FileExcelOutline,
  FileImageOutline,
  FileSearchOutline,
  FormOutline,
  HomeOutline,
  InboxOutline,
  InfoCircleOutline,
  LogoutOutline,
  MenuFoldOutline,
  MenuOutline,
  MenuUnfoldOutline,
  NotificationFill,
  NotificationOutline,
  OrderedListOutline,
  SettingFill,
  SettingOutline,
  SolutionOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  SolutionOutline,
  InboxOutline,
  ArrowLeftOutline,
  MenuFoldOutline,
  SettingFill,
  FileSearchOutline,
  OrderedListOutline,
  FileExcelOutline,
  CalendarOutline,
  NotificationFill,
  NotificationOutline,
  MenuOutline,
  HomeOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  SettingOutline,
  DeleteOutline,
  FileImageOutline,
  DeleteFill,
  LogoutOutline,
  EditOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule { }
